


















































import { RegistroOperacional } from "@/core/models/operacional";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { RegistroOperacionalService } from "@/core/services/operacional";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Vue, Component, Watch } from "vue-property-decorator";
import { PageBase } from "../core/models/shared/"

@Component
export default class Home extends PageBase {

  tipoUsuario = EnumTipoUsuario;

  registroOperacionalService: RegistroOperacionalService = new RegistroOperacionalService();
  registroOperacional: RegistroOperacional[] = [];
  
  item: RegistroOperacional = new RegistroOperacional();

  filtro: any = {
    situacaoId: 1
  };


  mounted() {
    this.Carregar();
  }

  Carregar(){
    if(!this.app.clienteId){
      this.registroOperacionalService.ListarComFiltro(0,-1,['numero'],[true],null,[],this.filtro, "Proposta.Cliente, Embarcacao,Porto,Proposta.Responsavel,Situacao").then(
        res=>{
          this.registroOperacional = res.data.items;
        },
        err=> AlertSimpleErr("Aviso!", err)
      )
    }
  }
}
